<template>
  <div class="">

    <div class="row">

      <div class="col-sm-12 col-md-12">
        <div class="card">
          <div class="card-header">
            <h3>ติดตามสถานะการสั่งซื้อ</h3>
          </div>
          <div class="card-body">
            <div class="col-sm-12 col-md-12">
              <table style="width: 100%;text-align: center;font-weight: bolder">
                <tr>
                  <v-btn
                    style="float: right"
                    color="primary"
                    title="คัดลอกเลขพัสดุ"
                          @click="myFunctionCopyTrackingUrl">
                    <i
                      class="fa fa-clipboard" aria-hidden="true"></i>
                    คัดลอกเลขพัสดุ                            </v-btn>
                  <br>
                </tr>
                <tr>
                  <td>วิธีการส่ง : <span
                    style="color:red;">
                    {{ order.tracking && order.tracking.transport_type_name ? order.tracking.transport_type_name : ''}}
                  </span>

                  </td>
                </tr>
                <tr>
                  <td><h4> เลขเช็คพัสดุ : <span v-if="order.PP_tax_no">
                    <v-textarea
                      style="width: 200px;margin: 0 auto;"
                      rows="2"
                      id="myFunctionCopyTrackingUrl"  v-model="order.PP_tax_no"></v-textarea>
                  </span>
                  </h4>
<!--                    <a-->
<!--                      :href="order.trackingUrl(order.transport_type_id).url ? order.trackingUrl(order.transport_type_id).url : ''"-->
<!--                      target="_blank">-->
<!--                      <span title="กดเช็คสถานะสินค้า" class="badge badge-warning"-->
<!--                                          style="font-size: 16px;">&nbsp;&nbsp; กดเช็คสถานะสินค้า &nbsp;&nbsp;</span>-->
<!--                    </a>-->
                    <a
                      style="text-decoration: none"
                      :href="order.tracking && order.tracking.url ? order.tracking.url : ''"
                      target="_blank">
                      <span title="กดเช็คสถานะสินค้า" class="warning-badge"
                                          style="font-size: 16px;">&nbsp;&nbsp; กดเช็คสถานะสินค้า &nbsp;&nbsp;</span>
                    </a>
                    <br>
                    <br>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4> วันที่จัดส่ง : <span v-if="order.PTP_date && order.PTP_time">
                       {{ order.PTP_date }} เวลา {{order.PTP_time}} น.
<!--                       {{substr(order.PTP_date,0,10)}} เวลา {{order.PTP_time}} น.-->
                    </span>
                  </h4>
                  </td>
                </tr>
                <tr>
                  <td>ค่าขนส่ง : {{order.transport_expense_price}} บาท
                  </td>

                </tr>
                <tr>
                  <td>ชื่อผู้รับ : {{order.recipient_name}}</td>
                </tr>
                <tr>
                  <td>ที่อยู่ผู้รับ :
                    <span v-if="order.recipient_address && order.recipient_address != 'null'">{{order.recipient_address}}</span>

                  </td>
                </tr>
                <tr>
                  <td style="color: red">*หากต้องการเปลี่ยนที่อยู่กรุณาติดต่อผู้ขาย</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>


      <div class="col-md-12">
        <div class="" v-if="order.order_status !== 'C'">
          <div class="card-header"><h3>สถานะสินค้า</h3>
          </div>
          <div class="card-body">
            <div style="margin-top: 20px;">
              <center>
                <ul class="timeline">
                  <li :class="order.order_status === 'PT' ||
                                order.order_status === 'PC' ||
                                order.order_status === 'PP' ||
                                order.order_status === 'PTP' ||
                                order.order_status === 'F' ? 'active' : '' ">
                    <i class="text fa fa-shopping-cart"> </i> <br>
                    <h6>จอง</h6>
                  </li>
                  <li :class="order.order_status === 'PT' ||
                                order.order_status === 'PC' ||
                                order.order_status === 'PP' ||
                                order.order_status === 'PTP' ||
                                order.order_status === 'F' ? 'active' : '' ">
                    <i class="text fa fa-credit-card"></i><br>

                    <h6>รอการชำระเงิน</h6>

                  </li>
                  <li :class="order.order_status === 'PT' ||
                                order.order_status === 'PC' ||
                                order.order_status === 'PP' ||
                                order.order_status === 'PTP' ||
                                order.order_status === 'F' ||
                                order.order_status !== 'C'
                                ? 'active' : '' ">
                    <i class="text fa fa-check-circle"></i><br>

                    <h6>กำลังตรวจสอบ</h6>
                  </li>
                  <li :class=" order.order_status === 'PT' ||
                                order.order_status === 'PC' ||
                                order.order_status === 'PP' ||
                                order.order_status === 'PTP' ||
                                order.order_status === 'F' ||
                                order.order_status !== 'C'
                                ? 'active' : '' ">
                    <i class="text fa fa-download"></i><br>

                    <h6>อยู่ระหว่างการผลิต</h6>
                  </li>
                  <li :class="order.order_status === 'PTP' ||
                                order.order_status === 'F' &&
                                order.order_status != 'C' ? 'active' : '' ">
                    <i class="text fa fa-truck"></i><br>

                    <h6>รอรับสินค้า</h6>
                  </li>
                </ul>
              </center>

            </div>
          </div>
          <br>
        </div>
      </div>


      <div class="col-sm-12 col-md-12">
        <div class="card">
          <div class="card-header"><h3>รายละเอียดสินค้า</h3></div>
          <div class="card-body">
            <div class="table-responsive text-nowrap">
              <table class="table table-striped" style="font-size: 13px">
                <thead>
                <tr>
                  <th>#</th>
                  <th>รูป/ดาวน์โหลด</th>
                  <th>รหัสลาย</th>
                  <th>ชื่อลาย</th>
                  <th>ยี่ห้อสินค้า</th>
                  <th>รุ่นสินค้า</th>
                  <th style="text-align: right">ราคา(บาท)</th>
                  <th>จำนวน(ชิ้น)</th>
                  <th style="text-align: right">ราคารวม(บาท)</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(order_detail,index) in order_details">
                  <td scope="row">
                    {{index+1}}
                  </td>
                  <td>
                    <center>


                      <div class="fileupload fileupload-new"
                           data-provides="fileupload">
                        <div class="fileupload-new thumbnail"
                             style="width:50px;height: 50px;">

                          <a  v-if="order_detail.pattern &&  order_detail.pattern.pattern_img"
                              :href="order_detail.pattern.pattern_img"
                             title="" target="_blank">
                            <img :src="order_detail.pattern.pattern_img"
                                 style="width:50px;height: 50px;"> </a>
                          <a
                            v-if="!order_detail.pattern.pattern_img && order_detail.pattern.location_files"
                            title="รูปอัพโหลดไฟล์เข้าระบบ" target="_blank"
                             :href="order_detail.pattern.location_files ?order_detail.pattern.location_files : order_detail.pattern.location_files">ไฟล์</a>
                        </div>
                      </div>

                    </center>
                  </td>
                  <td>
                    {{ order_detail.pattern ? order_detail.pattern.pattern_code : '-'}}
                  </td>
                  <td>
                    {{order_detail.pattern ? order_detail.pattern.pattern_name : '-'}}
                  </td>

                  <td> {{order_detail.product && order_detail.product.brand ? order_detail.product.brand.phone_brand_name : '-'}}</td>
                  <td>
                    {{order_detail.product && order_detail.product.phone_model ? order_detail.product.phone_model.phone_model_name : '-'}}
                  </td>
                  <td style="text-align:right">{{order_detail.product_price}}</td>
                  <td>
                    <center>{{order_detail.product_amount}}</center>
                  </td>
                  <td style="text-align:right">{{order_detail.product_price*order_detail.product_amount}}</td>
                </tr>


                <tr>
                  <td style="text-align:right"><b></b></td>
                  <td style="text-align:right"><b></b></td>
                  <td style="text-align:right"><b></b></td>
                  <td style="text-align:right"><b></b></td>
                  <td style="text-align:right"><b></b></td>
                  <td style="text-align:right"><b>รวม </b></td>
                  <td style="text-align:right;  "></td>
                  <td style="text-align:center;  ">{{amount}}</td>
                  <td style="text-align:right;  ">{{total}}</td>
                </tr>
                <tr>
                  <td style="text-align:right"><b></b></td>
                  <td style="text-align:right" colspan="4"><b>วิธีการส่งสินค้า</b></td>
                  <td style="text-align:right" colspan="3">{{order.transport_type ? order.transport_type.transport_type_name : '-'}}</td>
                  <td style="text-align:right">
                    <div
                      id="show_transport_expense_price">{{order.transport_expense_price ? order.transport_expense_price :  0.00}}</div>
                  </td>
                </tr>
                <tr>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right" colspan="3"><b>ส่วนลด</b></td>
                  <td style="text-align:right">{{order.order_price_discount ? order.order_price_discount : 0.00}}</td>
                </tr>
                <tr>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right" colspan="3"><b>ค่าเร่งด่วนการสั่งสินค้า</b></td>
                  <td style="text-align:right">{{total_importune ? total_importune : 0 }}</td>
                </tr>
                <tr>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right">&nbsp;</td>
                  <td style="text-align:right" colspan="3"><b>ค่าบริการอื่นๆ</b></td>
                  <td style="text-align:right">{{ order.configOrder ? order.configOrder : 0  }}</td>
                </tr>
                <tr>
                  <td style="text-align:right"><b></b></td>
                  <td style="text-align:right"><b></b></td>
                  <td style="text-align:right"><b></b></td>

                  <td style="text-align:right"><b></b></td>
                  <td style="text-align:right"><b></b></td>
                  <td style="text-align:right" colspan="3">
                    <b>ยอดรวมสุทธิ</b>
                  </td>
                  <td style="text-align:right;">
                    <h3>{{ order.order_expense ? order.order_expense : 0.00}} </h3>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-12">
        <div class="card">
          <div class="card-header"><h3>รายละเอียดการสั่งซื้อ</h3></div>
          <div class="card-body">
            <div class="col-md-12">
              <table style="width: 100%;font-size: 15px">
                <tr>
                  <td>
                    ใบสั่งซื้อเลขที่:{{ order.order_inv}}
                  </td>
                  <td style="text-align: right">
                    (
                    <span style="color: red"> สินค้า </span> &nbsp; <b
                    style="text-decoration: line-through;">
                    {{ order.order_expense - order.transport_expense_price}} </b>
                    <b style="text-decoration: line-through;">
                      <span v-if="order.PT_type === 'C'">เงินสด</span>
                      <span v-if="order.PT_type === 'T'">โอน</span>
                      <span v-if="order.PT_type === 'CD'">บัตรเครดิต</span>
                      <span v-if="order.PT_type === 'CC'">เครดิตเงินในกระเป๋าออนไลน์</span>
                      <span v-if="order.PT_type === 'CP'">เครดิตเสินค้า</span>
                    </b>

                    <span style="color: red">
                                  +
                                ค่าส่ง &nbsp; {{ order.transport_expense_price}}
                      <span v-if="order.importune === 'Y'"> + ค่าเร่งด่วนการสั่งสินค้า {{ total_importune }}</span>
                                        - ส่วนลด {{order.order_price_discount? order.order_price_discount :'0.00'}}

                    </span>
                    )

                  </td>
                </tr>
                <tr>
                  <td style="font-size: 15px">
                    สถานะ:
                    <span
                      class="warning-badge"
                      v-if="order.order_status === 'PT'">รอดำเนินการโอน</span>
                    <span
                      class="warning-badge"
                      v-if="order.order_status === 'PC'">รอการตรวจสอบ</span>
                    <span
                      class="success-badge"
                      v-if="order.order_status === 'PP'">อยู่ระหว่างดำเนินการผลิต</span>
                    <span
                      class="success-badge"
                      v-if="order.order_status === 'PTP'">เตรียมจัดส่ง</span>
                    <span
                      class="success-badge"
                      v-if="order.order_status === 'F'">จัดส่งเรียบร้อย</span>
                    <span
                      class="danger-badge"
                      v-if="order.order_status === 'C'">ยกเลิก</span>
                  </td>
                  <td style="text-align: right">
                    <h4 style="color: red">ยอดรวม: {{ order.order_expense}} บาท</h4>
                  </td>
                </tr>
              </table>
            </div>
            <table style="width: 100%;font-size: 13px" >
              <tr>
                <td>วันที่สั่งซื้อ : {{order.order_date_time}}</td>
                <td style="text-align: right">การชำระเงิน :
                  <span v-if="order.PT_type === 'C'" class="success-badge">เงินสด</span>
                  <span v-if="order.PT_type === 'T'" class="success-badge">โอน</span>
                  <span v-if="order.PT_type === 'CD'" class="success-badge">บัตรเครดิต</span>
                  <span v-if="order.PT_type === 'CC'" class="success-badge">เครดิตเงินในกระเป๋าออนไลน์</span>
                  <span v-if="order.PT_type === 'CP'" class="success-badge">เครดิตเสินค้า(ชิ้น)</span>

                  <span v-if="order.PT_type === 'T' && order.PT_slip">
                    <br>
                    <a style="color: red" :href="order.PT_slip"
                       target="_blank">หลักฐานการชำระเงิน</a>
                  </span>
                </td>
              </tr>
              <tr>
                <td>ผู้สั่งซื้อ : {{ order.member ? order.member.member_name : ''}}</td>
                <td style="text-align: right">วันที่ชำระเงิน : <span v-if="order.PT_date && order.PT_time">
<!--                  {{substr(order.PT_date,0,10)}} เวลา  {{order.PT_time}}  น.-->
                </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
// import instance from '../../../axios.service'
import Swal from "sweetalert2";
import {email, minLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import instance_order from "@/services/order";

export default {
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    total_importune : 0,
    amount : 0,
    total : 0,
    order_id : null,
    payment_types: [],
    order : {},
    order_details : [],
    'amount_options': [
      1, 5, 10, 20, 50, 100, 500, 1000
    ],
    selected: [],
    deposit : {},
    product_options: [],
    count: 0,
    product_select: 'ค้นหาสินค้า',
    purchase_order_type: 1, //ใบสั่งซื้อสินค้า Purchase Order 1    //
    length_for: 0,
    purchase_order_details_length: 7
  }),
  validations() {
    return {
      editedItem: {
        name: {required},
        value: {required},
      }
    }
  },
  computed: {
    sumTotal (){

    }
  },
  watch: {},

  created() {

  },
  mounted() {
    this.order_id = this.$route.params.id;
    this.getDetails();
  },
  methods: {
    getDetails(){
      this.$store.state.isLoading = true;
      instance_order.getOrderTracking(this.order_id).then(res => {
        this.order = res.data.data.order;
        this.order_details = res.data.data.order_details
        this.$store.state.isLoading = false;
      }).catch(err => {
        this.$store.state.isLoading = false;

      })
    },
    myFunctionCopyTrackingUrl() {
      /* Get the text field */
      var copyText = document.getElementById("myFunctionCopyTrackingUrl");


      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");

      /* Alert the copied text */
      alert("คัดลอกเลขพัสดุก่อน: " + copyText.value);
    }
  }
}
</script>

<style>
.card {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0px;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.125);
  border-image: initial;
  border-radius: 0.25rem;
}
.card-header{
  margin-bottom: 0px;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0px 0px;
}

.text {
  margin-top: -40px;
  position: absolute;
  color: #111;;
  margin-left: -8px;
}

.container {
  /*width: 50px;*/
  /*margin: auto;*/
  margin-top: 10px;
}

.timeline {
  counter-reset: unset;
  position: relative;
}

.timeline li {
  list-style: none;
  float: left;
  width: 20%;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  /*font-family: 'Dosis', sans-serif;*/
}

ul:nth-child(1) {
  color: #4caf50;
}

.timeline li:before {
  counter-increment: year;
  content: '';
  width: 50px;
  height: 50px;
  border: 3px solid #4caf50;
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 50px;
  margin: 0 auto 10px auto;
  background: #F1F1F1;
  color: #000;
  transition: all ease-in-out .3s;
  cursor: pointer;
}

.timeline li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: grey;
  top: 25px;
  left: -50%;
  z-index: -999;
  transition: all ease-in-out .3s;
}

.timeline li:first-child:after {
  content: none;
}

.timeline li.active {
  color: #555555;
}

.timeline li.active:before {
  background: #4caf50;
  color: #F1F1F1;
}

.timeline li.active + li:after {
  background: #4caf50;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: unset !important;
}

ul.timeline {
  list-style-type: none;
  position: relative;
  padding-left: 1.5rem;
}

/* Timeline vertical line */
ul.timeline:before {
  content: ' ';
  background: #fff;
  display: inline-block;
  position: absolute;
  left: 16px;
  width: 4px;
  height: 100%;
  z-index: 400;
  border-radius: 1rem;
}

li.timeline-item {
  margin: 20px 0;
}

/* Timeline item arrow */
.timeline-arrow {
  border-top: 0.5rem solid transparent;
  border-right: 0.5rem solid #fff;
  border-bottom: 0.5rem solid transparent;
  display: block;
  position: absolute;
  left: 2rem;
}

/* Timeline item circle marker */
li.timeline-item::before {
  content: ' ';
  background: #ddd;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #fff;
  left: 11px;
  width: 14px;
  height: 14px;
  z-index: 400;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}


/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/


.text-gray {
  color: #999;
}


</style>
